.board {
    /* width: 600px; */
}

.tile {
    width: 100%;
    height: 100%;
    background-color: cornflowerblue;
    border: 2px solid white;
    cursor: pointer;
}

.tile span.number {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
}

.text-italic {
    font-style: italic;
    font-size: 0.75rem;
}

.blank {
    opacity: 0;
}

.blankRight {
    transform: translateX(-100%);
}

.blankLeft {
    transform: translateX(100%);
}

.blankUp {
    transform: translateY(100%);
}

.blankDown {
    transform: translateY(-100%);
}

.links-container a {
    text-decoration: none;
    font-size: 0.75rem;
}